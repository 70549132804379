document.addEventListener('DOMContentLoaded', function() {
  const searchInputs = document.querySelectorAll('.search-filters-values');
  
  searchInputs.forEach((searchInput) => {
    searchInput.addEventListener('input', function() {
      const searchText = this.value.toLowerCase();
      const checkboxes = this.parentElement.querySelectorAll('.checkboxSelectFiltre');

      checkboxes.forEach(function(checkbox) {
          const label = checkbox.querySelector('label');
          const labelText = label.textContent.toLowerCase();

          if (labelText.includes(searchText)) {
              checkbox.style.display = 'block';
          } else {
              checkbox.style.display = 'none';
          }
      });
    });
  });

  $(".collapse.show").each(function () {
    $(this).prev(".card-header").find(".fa")
        .addClass("fa-minus").removeClass("fa-plus");
});
// Toggle plus minus icon on show hide
// of collapse element
$(".collapse").on('show.bs.collapse', function () {
    $(this).prev(".card-header").find(".fa")
        .removeClass("fa-plus").addClass("fa-minus");
}).on('hide.bs.collapse', function () {
    $(this).prev(".card-header").find(".fa")
        .removeClass("fa-minus").addClass("fa-plus");
});
  
});
