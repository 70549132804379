$(document).ready(function () {
    $('#create_account_password_second_help').addClass('hidden');
    // $('#create_account_password_first_help').addClass('hidden');

});

$('#create_account_password_first').on("keyup",function () {
    $("#danger").remove();
    $(".d-block").remove();

    $('#create_account_password_first_help').addClass('text-muted');
    if (this.value.length < 6)
    {
        $('#create_account_password_first_help').addClass('text-danger');
        $('#create_account_password_first_help').removeClass('text-muted');
    }

});
$('#create_account_password_second').on("keyup",function () {
    $('#danger').remove();
    $(".d-block").remove();
    let firstPassword =$('#create_account_password_first').val();
    let secondPassword =$('#create_account_password_second').val();
    if(secondPassword.length == 0)
    {
        $('#create_account_password_second_help').addClass('hidden');
        return;
    }
    if(firstPassword != secondPassword)
    {
        $('#create_account_password_second_help').removeClass('hidden');
        return;
    }
    $('#create_account_password_second_help').addClass('hidden');
    return;
    

});
