import Masonry from 'masonry-layout';
import jQueryBridget from 'jquery-bridget';
import imagesLoaded from 'imagesloaded';

jQueryBridget('masonry', Masonry, $);
jQueryBridget('imagesLoaded', imagesLoaded, $);

var grid  = $('.grid');

var cardDeck = $('.card-deck');

$('.nav-item').on('click', function() {
  setTimeout(function() {
  cardDeck.masonry({
    itemSelector: '.card',
    fitWidth: true,
    initLayout: true
  });
},200 )
});


if (grid.length) {

    /** Init masonry */

    var screenWidth = window.innerWidth;

    var isMobile = screenWidth < 768;

  var gridItemSelector = '.grid-item';

  grid.masonry({
    itemSelector:       gridItemSelector,
    fitWidth: true,
    initLayout:         false
  });


  var grid_items = grid.find(gridItemSelector);

  grid
  .imagesLoaded(
    function() {
      /** Display images now (masonry need to see them to calculate sizes / coords...) */
      grid_items.show();
      /** Refresh masonry layout */
      grid.masonry('layout');
  });

}

$( "#menu-nos-produits, #menu-produits").on( "click", function (e) {


  setTimeout(function() {   

    // trick to mask menu during ugly transition reloading 
    $('#content-category-menu').find('.parent a').css('color','#fff');
    $('#content-category-menu').masonry({
        itemSelector: '.parent',
        columnWidth: $('#content-category-menu').width()
    }).masonry('reloadItems');

    // remove not wanted last link (tous les produits)
    $('#content-category-menu').find(".parent").last().remove();

  },300 )

  // display when reload is done
  setTimeout(function() {   
    $('#content-category-menu').find('.parent a').css('color','#1f2329');
  },400 )
   
} )



$(document).ready(function() {

  $('#menu-nos-produits , #menu-produits').on('openMenu',
    function (objectEvent){

      $.get('/catalogue/categories/3001-epicerie', function(data) {
        $('#menu-products-categories').html(data);

       $('#content-category-menu-wrapper').addClass('loading');

       $('#content-category-menu').imagesLoaded(function() {
        $('#content-category-menu').masonry('reloadItems').masonry({
            itemSelector: '.parent',
        });
        $('#content-category-menu-wrapper').removeClass('loading');
        });


        
    }).fail(function() {
        console.error("Failed to load the content.");
        $('#content-category-menu-wrapper').removeClass('loading');
    });

       
 });


 $('#menu-nos-produits , #menu-produits').on('click', '.nav-category', function() {

      // Désactiver la carte active et activer la nouvelle carte
      $(this).siblings().find('.card').removeClass('active').addClass('inactive');
      $(this).find('.card').removeClass('inactive').addClass('active');

      // Récupérer l'URL de la catégorie
      var url = $(this).attr('data-url');

      // Masquer le contenu actuel avant de faire une requête AJAX

      var wrapper = $('#content-category-menu-wrapper');

      // Ajouter une classe temporaire ou utiliser un loader
      wrapper.addClass('loading');

      // Récupérer les nouvelles données via AJAX
      $.get(url, function(data) {
          updateContent(data);
      }).fail(function() {
          console.error("Failed to load the content.");
          wrapper.removeClass('loading'); // Retirer la classe en cas d'erreur
      });
  });
});

function updateContent(data) {
  var content = $('#content-category-menu');
  var wrapper = $('#content-category-menu-wrapper');

  // Masquer le contenu avant la mise à jour
  content.fadeOut(300, function() {
      // Mettre à jour le contenu
      content.html($('#content-category-menu', data).html());

      // Recharger Masonry après la mise à jour
      content.masonry('reloadItems').masonry({
          itemSelector: '.parent',
      });

      // Afficher le nouveau contenu avec un effet de transition
      content.fadeIn(300, function() {
          // Retirer la classe 'loading' une fois la transition terminée
          wrapper.removeClass('loading');
      });
  });
}