
window.addEventListener('load', function () {
  
  $(document).on('click', 'button.items-orderby', function() {
    document.location.search = $(this).data('url');
});

$('#modalInvoiceSchedule').on('show.bs.modal', function(e) {


let invoiceIds = e.relatedTarget.dataset.invoiceIds;
let url = e.relatedTarget.dataset.url;
let isManual = e.relatedTarget.dataset.isManual;

pap.ajax.url = url;
pap.ajax.method = 'GET';
pap.ajax.dataType = 'html';
pap.ajax.data = { "invoiceIds": invoiceIds , "isManual": isManual   };
pap.ajax.onSuccess = function (data) {
    $('#modalInvoiceSchedule').find('form').html(data);
    $('#recipients-fields-list').on('click', '.remove-recipient', function(e) {
        e.preventDefault();
        $(this).parent()
            .fadeOut()
            .remove();
    });

$('.no-use').remove();
//$('#invoice_schedule_period').parent().hide();
//$('#invoice_schedule_invoices').parent().hide();
$('#recipients-fields-list').find("label").hide();



$('.add-another-collection-widget').click(function (e) {

var list = $($(this).attr('data-list-selector'));
// Try to find the counter of the list or use the length of the list
var counter = list.data('widget-counter') || list.children().length;

// grab the prototype template
var newWidget = list.attr('data-prototype');

// replace the "__name__" used in the id and name of the prototype
// with a number that's unique to your emails
// end name attribute looks like name="contact[emails][2]"
newWidget = newWidget.replace(/__name__/g, counter);

// Increase the counter
counter++;
// And store it, the length cannot be used if deleting widgets is allowed
list.data('widget-counter', counter);

// create a new list element and add it to the list
var newElem = $(list.attr('data-widget-tags')).html(newWidget);

newElem.append("<a style='position:relative;top: -27px;left: -5px;' href='#' class='remove-recipient pull-right'><i style='color:red' class='fa fa-close-custom close '></i></a>");

newElem.appendTo(list);
});

if (location.pathname === '/espace-client/invoices') {
    const button = document.querySelector('.add-another-collection-widget');    
    button.click();
}

};
pap.ajax.onError = function (data) {
    console.log(data);
};
pap.ajax.execute();

});
$( ".invoice-bottom-panel" ).hide();


$('#check-all-invoices').on('click', () => {

        $('.invoice-check-input').each( function() {
            if (this.checked !== $('#check-all-invoices')[0].checked) {
            $(this).trigger('click');     
            }
        });
    

});

$('#invoices-download-panel').on('click', () => {
    var invoices = $('#invoices-download-panel').data('invoiceIds');
    var url = $('#invoices-download-panel').data('url') + '?invoiceIds[]=' + invoices.join('&invoiceIds[]=');
    window.location.href = url;
});

$('.invoice-check-input').on('click', function() {

let countChecked = 0;   
var invoices = [];
var amount = 0;


$('.invoice-check-input').each( function() {
    
    if (this.checked) {          
        invoices.push(parseInt($(this).data('invoice')));
        amount = amount + parseFloat($(this).data('amount'))
        countChecked ++;      
    }
});

 

    $("#nb-invoices").html(countChecked);
    $("#amount-invoices").html(Math.round(amount*100)/100);
    $(".send-invoices-link").attr('data-invoice-ids', JSON.stringify(invoices));
    
    if (  countChecked > 1 ) {
        $( ".invoice-bottom-panel" ).removeClass('d-none').show( "slow" );

    } else {
        $( ".invoice-bottom-panel" ).slideUp();
     }
});


});
